<template>
  <v-row class="justify-md-space-around justify-center align-center">
    <v-col cols="6">
      <p class="display-2">Paramètres</p>
      <p class="title">Disque</p>

      <v-progress-linear
        :color="settings.space.percentage > 60 ? 'error':'primary'"
        height="10"
        :value="settings.space.percentage">
      </v-progress-linear>
      <p class="body mb-0">Occupé: {{ settings.space.percentage | round }}%</p>
      <p class="caption mt-0">Espace libre: {{ toGo(settings.space.free) | round }}
         / {{ toGo(settings.space.size) | round }} Go</p>
      <p class="title">Déictiques</p>

      <p class="body">{{ settings.deictics }}</p>

      <p class="title">Stopwords</p>
      <p class="subtitle">Français</p>
      <p class="body">{{ settings.stopwords.fr }}</p>

      <p class="subtitle">Anglais</p>
      <p class="body">{{ settings.stopwords.en }}</p>
  </v-col>
</v-row>
</template>

<script>

import MixinInfo from '@/mixins/info';

export default {
  name: 'Settigns',
  mixins: [MixinInfo],
  data: () => ({
    settings: {
      deictics: '',
      stopwords: { fr: '', en: '' },
      space: { free: 0, size: 0, percentage: 0 },

    },
  }),

  methods: {
    format(str) {
      return str.trimEnd().split('\n').join(' / ');
    },

    toGo: (x) => (x / (1024 * 1024 * 1024)),
  },

  async mounted() {
    this.settings = await this.getSettings();
    this.settings.deictics = this.format(this.settings.deictics);
    this.settings.stopwords.en = this.format(this.settings.stopwords.en);
    this.settings.stopwords.fr = this.format(this.settings.stopwords.fr);
    this.settings.space.percentage = 100 - (this.settings.space.free * 100)
    / this.settings.space.size;
  },
};
</script>
